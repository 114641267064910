import React from "react"
import { useSelector } from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { userSelector, UserState } from "../hasura/slices/user"
import { postSlackMessage } from "../lib/helpers"

const CROSSWORDS = [
  ["bicycle", "tricycle", "biped", "quadruped", "tripod", "arthropod", "gastropod"],
  ["carnivore", "homicide", "regicide", "omnipotent", "herbivore", "omnivore", "herbicide"],
  ["millipede", "biped", "century", "quadruped", "centipede", "omnivore", "omniscient", "millennium", "carnivore"],
  ["microphone", "microscope", "telescope", "telephone", "hydrophone", "arthropod", "gastropod", "arthritis", "gastritis"],
  ["equilateral", "unilateral", "bilateral", "quadrilateral", "quadruped", "unicycle", "unicorn", "uniform"],
  ["monolith", "lithograph", "gastrolith", "microlith", "otolith", "megalith", "megapod", "gastropod"],
  ["hexagon", "octagon", "decagon", "pentagon", "polygon", "hexapod", "decade", "polygastric", "monogastric"],
  [
    "arthritis",
    "polygastric",
    "gastropod",
    "gastronomy",
    "gastritis",
    "gastroscope",
    "dermatitis",
    "monogastric",
    "gastrolith",
    "microscope",
    "telescope",
    "arthroscope",
    "endoscope",
  ],
  ["prescient", "omnipresent", "omniscient", "omnipotent", "omniarch", "omnivore"],
  ["macrocosm", "megapod", "macroscopic", "microeconomics", "macroeconomics", "microcosm", "microscopic", "microlith"],
  ["audiology", "audience", "audiogram", "audiophile", "audiologist", "audiometer", "inaudible", "audible"],
  ["bibliography", "bibliophobia", "bibliomania", "bibliology", "bibliophilia"],
  ["pentathlon", "athlete", "athletic", "triathlon", "triathlete", "decathlon", "biathlete", "biathlon"],
  ["geometry", "pedometer", "sclerometer", "audiometer", "thermometer", "telemeter", "altimeter", "trigonometry"],
  ["geometry", "geomorphology", "geology", "geologist", "geophile"],
  ["arthroscope", "telescope", "microscopic", "microscope", "gastroscope"],
  ["cardiology", "cosmology", "protozoology", "zoology", "anthropology", "cosmetology", "bacteriology", "biology"],
  ["macrocosm", "cosmonaut", "microcosm", "cosmology", "cosmologist"],
  ["cryptozoa", "cryptology", "cryptozoology", "cryptography", "cryptic", "cryptographer", "cryptogram", "cryptonym", "cryptolect", "decrypt"],
  ["magnanimous", "unanimity", "animal", "animation", "unanimous", "equanimity", "inanimate", "animism", "animosity"],
]

export default function ResourceLibrary() {
  const { user }: UserState = useSelector(userSelector)

  const openCrossword = (index: number, answerKey = false) => {
    postSlackMessage(`${user?.display_name} download crossword ${index}${answerKey ? " answers" : ""}`)
    window.open(`https://wordcraft-crosswords.s3.amazonaws.com/wordcraft-crossword-${index + 1}${answerKey ? "-ANSWERS" : ""}.pdf`)
  }

  return (
    <Layout>
      <SEO title="Resource Library" />
      <div className="max-width-1100px mx-auto">
        <h1 className="header page-top">Resource Library</h1>

        <div style={{ height: "1px" }} className="w-100 my-3 bg--gray3" />

        <div className="pb-4">
          <h2 className="main-header text-left m3-5 mb-3">Crosswords</h2>

          <div style={{ gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`, display: "grid", gap: "20px" }}>
            {CROSSWORDS.map((concepts, index) => (
              <div className="d-flex flex-column justify-content-between border p-3 rounded" key={index}>
                <div>
                  <p className="m-0 mb-1 text-m">Crossword {index + 1} </p>

                  <p className="text--gray6 text-xs mb-0">{concepts.join(", ")}</p>

                  <p className="text-xs text--gray8">{concepts.length} words</p>
                </div>

                <div className="d-flex">
                  <p onClick={() => openCrossword(index)} role="button" className="m-0 underline mr-3 text--gray8 hover-text--primary">
                    Download crossword
                  </p>

                  <p onClick={() => openCrossword(index, true)} role="button" className="m-0 underline text--gray8 hover-text--primary">
                    Download answer key
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="pb-5">
          <div style={{ height: "1px" }} className="w-100 my-3 bg--gray3" />

          <h2 className="main-header text-left m3-5 mb-3">Worksheets</h2>

          <p className="bold text-s text--secondary">COMING SOON</p>
        </div>
      </div>
    </Layout>
  )
}
